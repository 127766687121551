import { Controller } from "stimulus"
import { showTarget, hideTarget } from '../../helpers/visibility'

export default class extends Controller {
  static targets = [
    "locationInPerson", "locationInPersonDetailsWrapper",
    "locationPhone", "locationPhoneInboundOutboundInput", "locationPhoneInboundOutboundWrapper", "locationPhoneDetailsWrapper",
    "locationZoom", "locationZoomDetailsWrapper",
    "locationOther", "locationOtherLabelWrapper", "locationOtherDetailsWrapper"
  ]

  connect() {
    this.updateVisibility()
  }

  updateVisibility() {
    const locationInPerson = this.locationInPersonTarget.checked
    const locationPhone = this.locationPhoneTarget.checked
    const locationZoom = this.locationZoomTarget.checked
    const locationOther = this.locationOtherTarget.checked

    locationInPerson ? showTarget(this.locationInPersonDetailsWrapperTarget) : hideTarget(this.locationInPersonDetailsWrapperTarget)

    if(locationPhone) {
      showTarget(this.locationPhoneInboundOutboundWrapperTarget)

      const inboundOutbound = this.locationPhoneInboundOutboundInputTargets.find(input => input.checked).value
      if(inboundOutbound === 'inbound') {
        hideTarget(this.locationPhoneDetailsWrapperTarget)
      }
      else {
        showTarget(this.locationPhoneDetailsWrapperTarget)
      }
    }
    else {
      hideTarget(this.locationPhoneInboundOutboundWrapperTarget)
      hideTarget(this.locationPhoneDetailsWrapperTarget)
    }

    locationZoom ? showTarget(this.locationZoomDetailsWrapperTarget) : hideTarget(this.locationZoomDetailsWrapperTarget)

    if(locationOther) {
      showTarget(this.locationOtherLabelWrapperTarget)
      showTarget(this.locationOtherDetailsWrapperTarget)
    }
    else {
      hideTarget(this.locationOtherLabelWrapperTarget)
      hideTarget(this.locationOtherDetailsWrapperTarget)
    }
  }
}
