import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["packageWrapper", "addOnWrapper", "backdropWrapper", "lookupType", "staffWrapper"]

  connect() {
    this.updateVisibility()
  }

  updateVisibility() {
    const lookupType = this.lookupTypeTargets.find(input => input.checked).value
    if(lookupType === "Package") {
      this.packageWrapperTarget.classList.remove("d-none")
      this.addOnWrapperTarget.classList.add("d-none")
      this.backdropWrapperTarget.classList.add("d-none")
      if(this.hasStaffWrapperTarget) {
        this.staffWrapperTarget.classList.add("d-none")
      }
    }
    else if(lookupType === "Add On") {
      this.packageWrapperTarget.classList.add("d-none")
      this.addOnWrapperTarget.classList.remove("d-none")
      this.backdropWrapperTarget.classList.add("d-none")
      if(this.hasStaffWrapperTarget) {
        this.staffWrapperTarget.classList.add("d-none")
      }
    }
    else if(lookupType === "Staff") {
      this.packageWrapperTarget.classList.add("d-none")
      this.addOnWrapperTarget.classList.add("d-none")
      this.backdropWrapperTarget.classList.add("d-none")
      if(this.hasStaffWrapperTarget) {
        this.staffWrapperTarget.classList.remove("d-none")
      }
    }
    else if(lookupType === "Appointment") {
      this.packageWrapperTarget.classList.add("d-none")
      this.addOnWrapperTarget.classList.add("d-none")
      this.backdropWrapperTarget.classList.add("d-none")
      if(this.hasStaffWrapperTarget) {
        this.staffWrapperTarget.classList.remove("d-none")
      }
    }
    else {
      this.packageWrapperTarget.classList.add("d-none")
      this.addOnWrapperTarget.classList.add("d-none")
      this.backdropWrapperTarget.classList.remove("d-none")
      if(this.hasStaffWrapperTarget) {
        this.staffWrapperTarget.classList.add("d-none")
      }
    }
  }
}
