import { Controller } from "stimulus"
import { showTarget, hideTarget } from '../../helpers/visibility'

export default class extends Controller {
  static targets = [
    "location", "locationInPersonDetails", "locationPhoneDetails", "locationOtherDetails"
  ]

  connect() {
    console.log('hi there')
    this.updateVisibility()
  }

  updateVisibility() {
    const location = this.locationTarget.value

    switch(location) {
      case "In Person":
        showTarget(this.locationInPersonDetailsTarget)
        hideTarget(this.locationPhoneDetailsTarget)
        hideTarget(this.locationOtherDetailsTarget)
        break
      case "Phone":
        hideTarget(this.locationInPersonDetailsTarget)
        showTarget(this.locationPhoneDetailsTarget)
        hideTarget(this.locationOtherDetailsTarget)
        break
      case "":
        hideTarget(this.locationInPersonDetailsTarget)
        hideTarget(this.locationPhoneDetailsTarget)
        hideTarget(this.locationOtherDetailsTarget)
      default:
        hideTarget(this.locationInPersonDetailsTarget)
        hideTarget(this.locationPhoneDetailsTarget)
        showTarget(this.locationOtherDetailsTarget)
        break
    }
  }
}
